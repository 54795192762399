import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { Switch } from '../components';
import { useWorkersContext } from '../contexts/WorkersContext';

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { isMaster, handleIsMaster, setLocalStorage } = useWorkersContext();

  const handleChange = (e) => {
    handleIsMaster(isMaster);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      setLocalStorage(isMaster);
      setLoading(false);
      isMaster ? history.push('/display') : history.push('/');
    } catch (error) {
      setError('Failed to log in');
      setLoading(false);
    }
  };

  return (
    <>
      <Wrapper>
        <div className='card'>
          <h2>Logi Sisse</h2>
          {error && <p className='error'>{error}</p>}
          <form className='signup-form' onSubmit={handleSubmit}>
            <label htmlFor='email'>Kasutaja</label>
            <input type='email' required ref={emailRef} id='email' />
            <label htmlFor='password'>Parool</label>
            <input type='password' required ref={passwordRef} id='password' />
            <Switch checked={isMaster} onChange={handleChange} />
            <button disabled={loading} type='submit'>
              Logi sisse
            </button>
          </form>
        </div>
        <p className='version'>V1.2</p>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  label {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .card {
    margin: 0 auto;
    padding: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    max-width: 400px;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  input {
    line-height: 1.5;
    border: 1px solid #ced4da;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 0.5rem;
    padding: 0.375rem 0.75rem;
  }
  button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  .version {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0.5rem;
  }
  .signup-form {
    display: grid;
  }
  .have-account {
    text-align: center;
  }
  .error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    position: relative;
    padding: 1rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
`;

export default Login;
