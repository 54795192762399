import React, { useEffect } from 'react';
import { useFilterContext } from '../contexts/FilterContext';
import { useWorkersContext } from '../contexts/WorkersContext';
import GridView from './GridView';
import ListView from './ListView';

const WorkersList = () => {
  const { gridView, filteredWorkers: workers } = useFilterContext();
  const { fetchUsers, loading } = useWorkersContext();

  console.log(loading);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <h2
        style={{
          textAlign: 'center',
          paddingTop: '2rem',
          textTransform: 'none',
        }}
      >
        Laen andmeid....
      </h2>
    );
  } else {
    if (workers.length < 1) {
      return (
        <h2
          style={{
            textAlign: 'center',
            paddingTop: '2rem',
            textTransform: 'none',
          }}
        >
          Ühtegi töötajat pole sisse loginud!
        </h2>
      );
    }

    if (gridView === false) {
      return <ListView workers={workers} />;
    } else {
      return <GridView workers={workers} />;
    }
  }
};

export default WorkersList;
