import React, { useContext, useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import { getLocalStorage } from '../utils/helpers';

const WorkersContext = React.createContext();

export const WorkersProvider = ({ children }) => {
  const [data, setData] = useState([]);

  // loading spinner for data fetching
  const [loading, setLoading] = useState(true);

  // isMaster state. Is current user a worker or master. Get initial state from local storage, if it is saved.
  const [isMaster, setIsMaster] = useState(getLocalStorage('role', false));

  // switch click changes state to true or false
  const handleIsMaster = (isMaster) => {
    setIsMaster(!isMaster);
  };

  // save user role to localhost. true - department master, false - worker
  const setLocalStorage = (role) => {
    role
      ? localStorage.setItem('role', true)
      : localStorage.setItem('role', false);
  };

  // clear local storage from user role when logging out
  const clearLocalStorage = () => {
    localStorage.removeItem('role');
    localStorage.removeItem('working');
    localStorage.removeItem('alert');
  };

  // fetch all users from server
  const fetchUsers = () => {
    setLoading(true);
    const usersRef = ref(database, 'ombleja/');

    onValue(usersRef, (response) => {
      const info = response.val();

      let users = info ? Object.values(info) : null;

      setData(users);
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const value = {
    data,
    loading,
    isMaster,
    handleIsMaster,
    setLocalStorage,
    clearLocalStorage,
    fetchUsers,
  };

  return (
    <WorkersContext.Provider value={value}>{children}</WorkersContext.Provider>
  );
};

export const useWorkersContext = () => {
  return useContext(WorkersContext);
};
