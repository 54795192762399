import React from 'react';
import styled from 'styled-components';
import { WorkersList, Sort } from '../components';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { useWorkersContext } from '../contexts/WorkersContext';

const Display = () => {
  const { logout } = useAuth();
  const history = useHistory();

  const { clearLocalStorage } = useWorkersContext();

  const handleLogout = async () => {
    try {
      clearLocalStorage();
      await logout();
      history.push('/login');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Wrapper>
        <h1 className='title'>Töötajad</h1>
        {/* {error && <p className='error'>{error}</p>} */}
        <Sort />
        <WorkersList />
        <button className='logout-btn' onClick={handleLogout}>
          Logi välja
        </button>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 2rem;

  h1 {
    font-size: 2rem;
  }

  .logout-btn {
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: 1rem;
    cursor: pointer;
    margin: 1.5rem;
    width: 100px;
    height: 50px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 0.25rem;
  }

  @media screen and (max-width: 792px) {
    margin: 1rem;
    .title {
      font-size: 1.25rem;
      padding-top: 0;
    }
  }
`;
export default Display;
