import React, { useContext, useState, useEffect } from 'react';
import { useWorkersContext } from './WorkersContext';
import { getLocalStorage } from '../utils/helpers';

const FilterContext = React.createContext();

export const FilterProvider = ({ children }) => {
  const { data: allWorkers } = useWorkersContext();
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [gridView, setGrid_View] = useState(getLocalStorage('gridview', true));

  // save gridview state to localStorage, to save user setting.
  const setGridLocalStorage = (state) => {
    localStorage.setItem('gridview', state);
  };

  const setGridView = () => {
    setGrid_View(true);
    setGridLocalStorage(true);
  };

  const setListView = () => {
    setGrid_View(false);
    setGridLocalStorage(false);
  };

  //set filteredWorkers when allWorkers data changes
  // this useEffect is used, when we start filtering workers.
  useEffect(() => {
    setFilteredWorkers(allWorkers);
  }, [allWorkers]);

  const value = {
    filteredWorkers,
    gridView,
    setGridView,
    setListView,
  };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
