import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AskWorkBtn, SendAlertBtn } from '../components';
import { useAuth } from '../contexts/AuthContext';
import { useWorkersContext } from '../contexts/WorkersContext';
import { useHistory } from 'react-router-dom';
import { database } from '../firebase';
import { set, ref } from 'firebase/database';
import { checkInternetConnection, getLocalStorage } from '../utils/helpers';

const Dashboard = () => {
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();
  const [askWorkLoading, setAskWorkLoading] = useState(false); //ask work button loading state
  const [sendAlertLoading, setSendAlertLoading] = useState(false); // send alert button loading state
  const { clearLocalStorage } = useWorkersContext();
  const history = useHistory();
  const [working, setWorking] = useState(getLocalStorage('working', true));
  const [isAlert, setIsAlert] = useState(getLocalStorage('alert', false));
  const [isLoggedIn, setIsLoggedIn] = useState(
    getLocalStorage('isLoggedIn', true)
  );

  // set both button loading states to false
  const setLoadingFalse = () => {
    setAskWorkLoading(false);
    setSendAlertLoading(false);
  };

  // separate user name from user e-mail
  const user = currentUser.email.split('@')[0];

  // set database reference
  const dbRef = ref(database, 'ombleja/' + currentUser.uid);

  // log out logic - set isLoggedIn status to false when logging out and set all other states to default.
  const handleLogOutFunc = () => {
    if (checkInternetConnection()) {
      setIsLoggedIn(false);
      setIsAlert(false);
      setWorking(true);
    } else {
      alert('Internetiühenduse probleemid!');
    }
  };

  // "logout button" logic. Remove item from database and also clear localStorage
  const handleLogout = async () => {
    try {
      setError('');
      handleLogOutFunc();
      clearLocalStorage();
      await logout();
      history.push('/login');
    } catch (error) {
      setError('Ei saanud välja logida');
    }
  };

  // "ask work" button logic
  const handleClick = () => {
    if (checkInternetConnection()) {
      setWorking(!working);
      setAskWorkLoading(true);
    } else {
      setLoadingFalse();
      alert('Internetiühenduse probleemid!');
    }
  };

  // "alert" button logic
  const handleAlert = () => {
    if (checkInternetConnection()) {
      setIsAlert(!isAlert);
      setSendAlertLoading(true);
    } else {
      setLoadingFalse();
      alert('Internetiühenduse probleemid!');
    }
  };

  // save current statuses to localStorage
  const setUserCurrentStatus = (working, isAlert) => {
    localStorage.setItem('working', working);
    localStorage.setItem('alert', isAlert);
  };

  // send data to database and also trigger save states to localStorage
  const setUserToDatabase = async () => {
    try {
      await set(dbRef, {
        userId: user,
        isWorking: working,
        isAlert,
        isLoggedIn,
      });
      setLoadingFalse();
      setUserCurrentStatus(working, isAlert);
    } catch (error) {
      setLoadingFalse();
      alert('Probleem päringu saatmisega!');
      console.log(`There was an error when creating user: ${error}`);
    }
  };

  // add user data to database when loading component, when changing statuses
  useEffect(() => {
    setUserToDatabase();
    // eslint-disable-next-line
  }, [working, isAlert, isLoggedIn]);

  return (
    <Wrapper>
      <div className='card'>
        <div className='header-container'>
          <p className='user-info'>Kasutaja</p>
          <h2>{user}</h2>
          {error && <p className='error'>{error}</p>}
        </div>
        <div className='btn-container'>
          <AskWorkBtn
            loading={askWorkLoading}
            handleClick={handleClick}
            working={working}
          />
          <SendAlertBtn
            loading={sendAlertLoading}
            handleAlert={handleAlert}
            isAlert={isAlert}
          />
        </div>
      </div>
      <div className='have-account'>
        <button className='logout-btn' onClick={handleLogout}>
          Logi välja
        </button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  .card {
    margin: 0 auto;
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 0.25rem;
    max-width: 400px;
  }
  .user-info {
    text-align: center;
    color: black;
    font-size: 2rem;
    margin-bottom: 0;
  }
  .btn-container {
    display: flex;
    gap: 1rem;
    width: auto;
    align-items: center;
  }
  .logout-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1rem;
    cursor: pointer;
    margin: 1.5rem;
    width: 100px;
    height: 50px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #0d6efd;
    border-color: #0d6efd;
    border-radius: 0.25rem;
  }
`;

export default Dashboard;
