import React from 'react';
import ListItem from './ListItem';
import styled from 'styled-components';

const ListView = ({ workers }) => {
  return (
    <Wrapper className='section-center'>
      <div className='listview-table'>
        <table className='workers'>
          <tbody>
            <tr>
              <th>Olek</th>
              <th>Kasutaja</th>
              <th>Teade</th>
            </tr>
            {workers.map((worker, index) => {
              return <ListItem key={index} {...worker} />;
            })}
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  .workers {
    margin-top: 12px;
    width: 80vw;
    max-width: 700px;
  }
  .workers th {
    padding-bottom: 12px;
    text-align: start;
  }
`;

export default ListView;
