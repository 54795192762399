import React from 'react';
import styled from 'styled-components';

const Switch = ({ checked, onChange }) => {
  return (
    <Wrapper>
      <label className='label'>
        <div className='label-text'>õmbleja</div>
        <div className='toggle'>
          <input
            className='toggle-state'
            type='checkbox'
            checked={checked}
            name='check'
            value='check'
            id='check'
            onChange={onChange}
          />
          <div className='indicator'></div>
        </div>
        <div className='label-text'>meister</div>
      </label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: auto;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  font-weight: 300;
  .label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #394a56;
  }

  .label-text {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .toggle {
    isolation: isolate;
    position: relative;
    height: 1.6rem;
    width: 3.2rem;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
      4px 4px 4px 0px #d1d9e6 inset, -4px -4px 4px 0px #ffffff inset;
  }

  .toggle-state {
    display: none;
  }

  .indicator {
    height: 100%;
    width: 200%;
    background: #0d6efd;
    border-radius: 15px;
    transform: translate3d(-75%, 0, 0);
    transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
  }

  .toggle-state:checked ~ .indicator {
    transform: translate3d(25%, 0, 0);
  }
`;

export default Switch;
