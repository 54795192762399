import { Dashboard, Login, Display } from './pages';
import { PrivateRoute } from './components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/login' component={Login} />
        <PrivateRoute exact path='/display' component={Display} />
        <PrivateRoute exact path='/' component={Dashboard} />
        {/* <Route path='/signup' component={Signup} /> */}
      </Switch>
    </Router>
  );
}

export default App;
