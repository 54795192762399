import React, { useRef, useState } from 'react';
import styled from 'styled-components';
// import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

const Signup = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  // const { signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // const history = useHistory();
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      // we return (exit) because there was an error.
      return setError('Passwords do not match');
    }

    if (checked) {
      try {
        setError('');
        alert('Palun pöördu administraatori poole!');
        // setLoading(true);
        // await signup(emailRef.current.value, passwordRef.current.value);
        // setLoading(false);
        // history.push('/');
      } catch (error) {
        console.log(error);
        setError('Failed to create an account');
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Wrapper>
        <div className='signup'>
          <h2>Sign up</h2>
          {error && <p className='error'>{error}</p>}
          <form className='signup-form' onSubmit={handleSubmit}>
            <label htmlFor='email'>Email</label>
            <input type='email' required ref={emailRef} id='email' />
            <label htmlFor='password'>Password</label>
            <input type='password' required ref={passwordRef} id='password' />
            <label htmlFor='password-confirm'>Password confirmation</label>
            <input
              type='password'
              required
              ref={passwordConfirmRef}
              id='password-confirm'
            />
            <div className='checks'>
              <label className='container'>
                <input type='checkbox' />
                <span className='checkmark'></span>
              </label>
              <label className='container'>
                <input type='checkbox' />
                <span className='checkmark'></span>
              </label>
              <label className='container'>
                <input
                  type='checkbox'
                  checked={checked}
                  onChange={handleChange}
                />
                <span className='checkmark'></span>
              </label>
            </div>
            <button disabled={loading} type='submit'>
              Sign Up
            </button>
          </form>
        </div>
        <div className='have-account'>
          Already have an account? <Link to='/login'>Log In</Link>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 1rem;
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  label {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .signup {
    margin: 0 auto;
    margin-top: 3rem;
    padding: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    max-width: 400px;
  }
  input {
    line-height: 1.5;
    border: 1px solid #ced4da;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
  }
  button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  .signup-form {
    display: block;
    display: grid;
  }
  .have-account {
    text-align: center;
  }
  .error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    position: relative;
    padding: 1rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .container {
    text-align: center;
  }
  .checks {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
`;

export default Signup;
