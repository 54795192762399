import React from 'react';
import GridItem from './GridItem';
import styled from 'styled-components';

const GridView = ({ workers }) => {
  return (
    <Wrapper className='section-center'>
      {workers.map((worker, index) => {
        return <GridItem key={index} {...worker} />;
      })}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding-top: 2rem;
  width: auto;
`;

export default GridView;
