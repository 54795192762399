import React from 'react';
import styled from 'styled-components';
import {
  ImCross,
  ImCheckmark,
  ImNotification,
  ImUserMinus,
} from 'react-icons/im';

const ListItem = ({ isWorking, userId, isAlert, isLoggedIn }) => {
  return (
    <Wrapper className='table-row'>
      <td>
        <div
          className={`item-color ${
            isLoggedIn
              ? isAlert
                ? 'orange'
                : isWorking
                ? 'green'
                : 'red'
              : 'gray'
          }`}
        >
          {isLoggedIn ? (
            isAlert ? (
              <ImNotification className='icon' />
            ) : isWorking ? (
              <ImCheckmark className='icon' />
            ) : (
              <ImCross className='icon' />
            )
          ) : (
            <ImUserMinus className='icon' />
          )}
        </div>
      </td>
      <td>{userId}</td>
      <td>
        {isLoggedIn
          ? isAlert
            ? 'probleem'
            : isWorking
            ? 'töötan'
            : 'ootan tööd'
          : 'välja logitud'}
      </td>
    </Wrapper>
  );
};

const Wrapper = styled.tr`
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
    text-transform: capitalize;
  }
  .item-color {
    width: 100px;
    height: 25px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  }
  .icon {
    color: white;
  }
  .orange {
    background: #ffb347;
  }
  .red {
    background: #dc3545;
  }
  .green {
    background: #198754;
  }
  .gray {
    background: gray;
  }
`;

export default ListItem;
