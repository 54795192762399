import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { AuthProvider } from './contexts/AuthContext';
import { WorkersProvider } from './contexts/WorkersContext';
import { FilterProvider } from './contexts/FilterContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <WorkersProvider>
        <FilterProvider>
          <App />
        </FilterProvider>
      </WorkersProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
